import React, { useState } from 'react';
import axios from 'axios';
import './CheckDunes.css';

function CheckDunes() {
  const [inputAddress, setInputAddress] = useState('');
  const [dunes, setDunes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const API_URL = 'https://wonky-ord-v2.dogeord.io/dunes/balance/';

  const fetchDunes = async (address) => {
    setLoading(true);
    setError('');
    try {
      // Add cache-busting query parameter only
      const response = await axios.get(`${API_URL}${address}?show_all=true&_=${new Date().getTime()}`);
      setDunes(response.data.dunes);
    } catch (error) {
      console.error('Error fetching Dunes:', error);
      setError('Error fetching Dunes: ' + (error.response ? error.response.data.message : error.message));
    } finally {
      setLoading(false);
    }
  };

  const handleCheck = () => {
    if (inputAddress) {
      fetchDunes(inputAddress);
    }
  };

  return (
    <div className="check-dunes-container">
      <h2>DUNES BALANCE CHECKER</h2>
      <div className="input-group">
        <input
          type="text"
          placeholder="Enter Wallet Address"
          value={inputAddress}
          onChange={(e) => setInputAddress(e.target.value)}
        />
        <button onClick={handleCheck}>CHECK</button>
      </div>
      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
      <div className="dunes-list">
        {dunes.map((dune, index) => (
          <div key={index} className="dune-item">
            <p>{dune.dune} {dune.symbol} {dune.total_balance}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CheckDunes;
