import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './MintDune.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';



function MintDune({ isWalletConnected, walletAddress, handlePayment }) {
  const [mintableDunes, setMintableDunes] = useState([]);
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedDune, setSelectedDune] = useState(null); // Holds the selected dune object
  const [batchAmount, setBatchAmount] = useState('20');
  const [address, setAddress] = useState('');
  const [status, setStatus] = useState('');
  const [etchTxId, setEtchTxId] = useState('');
  const [paymentAddress, setPaymentAddress] = useState('');
  const [fee, setFee] = useState(0);
  const [orderId, setOrderId] = useState(null);
  const [showPaymentInfo, setShowPaymentInfo] = useState(true);
  const [copiedPaymentAddress, setCopiedPaymentAddress] = useState(false);
  const [copiedFee, setCopiedFee] = useState(false);


  // Fetch mintable dunes on page load
  useEffect(() => {
    const fetchMintableDunes = async () => {
      try {
        const response = await axios.get('https://spooksociety.info/mintable_dunes.json'); // Update with the correct URL
        setMintableDunes(response.data);
      } catch (error) {
        console.error('Error fetching mintable dunes:', error);
      }
    };

    fetchMintableDunes();
  }, []);

  // Handle search input
  const handleSearchChange = (e) => {
    const value = e.target.value.toUpperCase().replace(/ /g, '•'); // Convert to uppercase and replace spaces with dots
    setSearch(value);

    if (value.length > 0) {
      const filteredSuggestions = mintableDunes.filter((dune) =>
        dune.ticker.startsWith(value)
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  // Handle selecting a dune from suggestions
  const handleSuggestionClick = (dune) => {
    setSearch(dune.ticker);
    setSelectedDune(dune); // Set the selected dune object
    setSuggestions([]);
  };

  const handleMint = async () => {
    if (!selectedDune) {
      setStatus('Please select a valid dune.');
      return;
    }

    if (batchAmount > 20) {
      setStatus('The maximum number of mints is 20 at a time.');
      return; // Stop execution if the amount is invalid
    }


    try {
      setStatus('Creating order...');
      const response = await axios.post('https://spooksociety.info/api/mint', {
        duneId: selectedDune.duneId,
        amount: selectedDune.amount,
        batchAmount,
        address,
      });
      setFee(response.data.fee);
      setOrderId(response.data.orderId);
      setPaymentAddress(response.data.paymentAddress);
      setStatus('Pending payment...');
      setShowPaymentInfo(true);

      if (isWalletConnected) {
        handlePayment(response.data.paymentAddress, response.data.fee);
        pollPaymentStatus(response.data.orderId); // Start polling immediately
      } else {
        pollPaymentStatus(response.data.orderId); // Start polling for payment status
      }


    } catch (error) {
      console.error('Error initiating mint:', error);
      setStatus('Error initiating mint: ' + error.message);
    }
  };

  
  const handleMintAgain = () => {
    setStatus('');
    setEtchTxId('');
    setFee(0);
    setOrderId(null);
    setPaymentAddress('');
    setShowPaymentInfo(true);
  };



  // Poll payment status
  const pollPaymentStatus = async (orderId) => {
    try {
      console.log('Polling payment status for order:', orderId);
      const response = await axios.get(`https://spooksociety.info/api/status/${orderId}`);
      console.log('Payment status response:', response.data);

      if (response.data.status === 'completed') {
        setShowPaymentInfo(false); // Hide payment info
        setStatus(''); // Display final status
        setEtchTxId(response.data.etchTxId); // Set the transaction ID
      }
 else if (response.data.status === 'Minting...') {
        setStatus('Payment confirmed. Minting...');
        setTimeout(() => pollPaymentStatus(orderId), 5000);
      } else if (response.data.status === 'error') {
        setStatus(`Error minting. Order ID: ${orderId}`);
      } else {
        setStatus('Pending payment...');
        setTimeout(() => pollPaymentStatus(orderId), 5000);
      }
    } catch (error) {
      console.error('Error polling payment status:', error);
      setStatus('Error checking payment status. Retrying...');
      setTimeout(() => pollPaymentStatus(orderId), 5000);
    }
  };

  return (
    <div className="container">
      <h2>Mint Dunes</h2>
      {status && <p className="status-message">{status}</p>}
      {!orderId ? (
        <>
          <div className="input-group">
            <input
              type="text"
              placeholder="Search Dune Ticker"
              value={search}
              onChange={handleSearchChange}
              autoComplete="off"
            />
            {suggestions.length > 0 && (
              <div className="suggestions-container">
                {suggestions.map((dune) => (
                  <div
                    key={dune.duneId}
                    className="suggestion-item"
                    onClick={() => handleSuggestionClick(dune)}
                  >
                    {dune.ticker}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="input-group">
            <input
              type="number"
              placeholder="Amount per Mint"
              value={selectedDune ? selectedDune.amount : ''}
              readOnly
            />
          </div>
          <div className="input-group">
            <input
              type="number"
              placeholder="Number of Mints"
              value={batchAmount}
              onChange={(e) => {
                const value = parseInt(e.target.value, 10);
                if (value > 20) {
                  setStatus('The maximum number of mints is 20 at a time.');
                } else {
                  setBatchAmount(value);
                  setStatus(''); // Clear the error message if valid
                }
              }}
              min="1"
              max="20"
            />
          </div>
          <div className="input-group">
            <input
              type="text"
              placeholder="Wallet Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <button onClick={handleMint}>Mint</button>
        </>
      ) : (
        <>
          {showPaymentInfo && (
            <>
              <div className="copy-container">
                <p>
                  <span className="payment-label desktop-only">Payment Address:</span> {paymentAddress}{' '}
                  <button
                    className={`copy-button ${copiedPaymentAddress ? 'copied' : ''}`}
                    onClick={() => {
                      navigator.clipboard.writeText(paymentAddress);
                      setCopiedPaymentAddress(true);
                      setTimeout(() => setCopiedPaymentAddress(false), 2000); // Reset after 2 seconds
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </button>
                </p>
              </div>
              <div className="copy-container">
                <p>
                  <span className="payment-label desktop-only">Fee:</span> {fee} DOGE{' '}
                  <button
                    className={`copy-button ${copiedFee ? 'copied' : ''}`}
                    onClick={() => {
                      navigator.clipboard.writeText(fee);
                      setCopiedFee(true);
                      setTimeout(() => setCopiedFee(false), 2000); // Reset after 2 seconds
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </button>
                </p>
              </div>
            </>
          )}

        </>
      )}
      {etchTxId && (
        <div className="status-container">
          <p>MINTING COMPLETE</p>
          <p>TX ID: {etchTxId}</p>
          <button onClick={handleMintAgain}>Mint Again</button>
        </div>
      )}
    </div>
  );
}

export default MintDune;