import React, { useState } from 'react';
import './SideNavigation.css';

function SideNavigation({ currentView, onNavigate }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div
      className="hamburger-container"
      onMouseEnter={() => setDropdownOpen(true)}
      onMouseLeave={() => setDropdownOpen(false)}
    >
      <button className="hamburger-menu">
        <div className="hamburger-lines"></div>
        <div className="hamburger-lines"></div>
        <div className="hamburger-lines"></div>
      </button>
      <div className={`dropdown ${dropdownOpen ? 'open' : ''}`}>
        {currentView !== 'main' && (
          <button onClick={() => onNavigate('etcher')}>MINT/ETCH</button>
        )}
        {currentView !== 'dunesList' && (
          <button onClick={() => onNavigate('dunesList')}>ETCHED DUNES</button>
        )}
        {currentView !== 'checkDunes' && (
          <button onClick={() => onNavigate('checkDunes')}>CHECK WALLET</button>
        )}
      </div>
    </div>
  );

}

export default SideNavigation;
