import React, { useState } from 'react';
import MintDune from './components/MintDune';
import DeployDune from './components/DeployDune';
import MyDogeWallet from './components/MyDogeWallet';
import DunesList from './components/DunesList';
import CheckDunes from './components/CheckDunes'; // Import the CheckDunes component
import SideNavigation from './components/SideNavigation';
import './App.css';
import logo from './dunetcher.png';

function App() {
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [walletBalance, setWalletBalance] = useState(0);
  const [view, setView] = useState('main');
  const switchToEtcherView = () => setView('main');


  const handleWalletConnect = ({ address, balance }) => {
    setWalletAddress(address);
    setWalletBalance(balance);
    setIsWalletConnected(true);
  };

  const handleWalletDisconnect = () => {
    setWalletAddress('');
    setWalletBalance(0);
    setIsWalletConnected(false);
  };

  const handlePayment = (paymentAddress, fee) => {
    if (window.doge && window.doge.requestTransaction) {
      window.doge.requestTransaction({
        recipientAddress: paymentAddress,
        dogeAmount: fee,
      }).then(txRes => {
        console.log('Payment transaction result:', txRes);
        // Handle successful payment (if needed)
      }).catch(error => {
        console.error('Payment failed:', error);
        // Handle payment failure (if needed)
      });
    }
  };

  const switchToMainView = () => setView('main');
  const switchToDunesListView = () => setView('dunesList');
  const switchToCheckDunesView = () => setView('checkDunes');

  return (
    <div className="App">
      <header className="App-header">
        <a href="https://www.onchainhustlers.com/barklabs" className="barklabs-link">BARKLABS</a>
        <div className="header-right">
          <SideNavigation currentView={view} onNavigate={(view) => {
            if (view === 'etcher') {
              switchToEtcherView();
            } else {
              setView(view);
            }
          }} />

          <MyDogeWallet
            onConnect={handleWalletConnect}
            onDisconnect={handleWalletDisconnect}
            onPayment={handlePayment}
          />
        </div>
      </header>
      <div className="logo-container">
        <img src={logo} alt="Dunetcher Logo" className="logo" />
      </div>
      <div className="content">
        {view === 'main' ? (
          <>
            <MintDune
              isWalletConnected={isWalletConnected}
              walletAddress={walletAddress}
              handlePayment={handlePayment}
            />
            <DeployDune
              isWalletConnected={isWalletConnected}
              walletAddress={walletAddress}
              handlePayment={handlePayment}
            />
          </>
        ) : view === 'dunesList' ? (
          <DunesList />
        ) : view === 'checkDunes' ? (
          <CheckDunes walletAddress={walletAddress} />
        ) : (
          <CheckDunes />
        )}
      </div>
    </div>
  );
}

export default App;
