import React, { useEffect, useState } from 'react';
import './MyDogeWallet.css'; // Ensure your CSS file is imported
import MyDogeIcon from '../mydoge.svg';



const MyDogeWallet = ({ onConnect, onDisconnect, onPayment }) => {
  const [myDogeMask, setMyDogeMask] = useState(null);
  const [connected, setConnected] = useState(false);
  const [address, setAddress] = useState('');
  const [balance, setBalance] = useState(0);
  const [isMobile, setIsMobile] = useState(false); // Added state for mobile check

  useEffect(() => {
    // Check if the device is mobile
    setIsMobile(window.innerWidth <= 600); // Added mobile check

    const initializeDogeWallet = () => {
      if (window.doge?.isMyDoge) {
        setMyDogeMask(window.doge);
      }
    };

    window.addEventListener('doge#initialized', initializeDogeWallet, { once: true });
    initializeDogeWallet();

    return () => {
      window.removeEventListener('doge#initialized', initializeDogeWallet);
    };
  }, []);

  const connectWallet = async () => {
    if (myDogeMask) {
      try {
        const connectRes = await myDogeMask.connect();
        setAddress(connectRes.address);
        setBalance(connectRes.balance / 100000000); // Convert satoshis to DOGE
        setConnected(true);
        onConnect(connectRes);
      } catch (error) {
        console.error('Connection failed:', error);
      }
    }
  };

  const disconnectWallet = async () => {
    if (myDogeMask) {
      try {
        await myDogeMask.disconnect();
        setConnected(false);
        setAddress('');
        setBalance(0);
        onDisconnect();
      } catch (error) {
        console.error('Disconnection failed:', error);
      }
    }
  };

  const handlePayment = async (recipientAddress, dogeAmount) => {
    if (myDogeMask && connected) {
      try {
        const txRes = await myDogeMask.requestTransaction({ recipientAddress, dogeAmount });
        console.log('Payment transaction result:', txRes);
        onPayment(txRes);
      } catch (error) {
        console.error('Payment failed:', error);
      }
    }
  };

  return (
    <div className="mydoge-wallet-container">
      {connected ? (
        <div className="wallet-info">
          <p>{address}</p>
          <p>Balance: {balance.toFixed(2)} DOGE</p>
          <button className="disconnect-button" onClick={disconnectWallet}>Disconnect</button>
        </div>
      ) : (
        !isMobile && (
          <button className="connect-button" onClick={connectWallet}>
            <img src={MyDogeIcon} alt="MyDoge Icon" className="mydoge-icon" />
            MyDoge
          </button>
        )
      )}
    </div>
  );
};

export default MyDogeWallet;
