import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './DunesList.css';

function DunesList() {
  const [dunes, setDunes] = useState([]);
  const [selectedDuneIndex, setSelectedDuneIndex] = useState(null);
  const [duneDetails, setDuneDetails] = useState(null);
  const [sortOption, setSortOption] = useState('Recent');
  const [searchTerm, setSearchTerm] = useState('');
  const [copiedButtonIndex, setCopiedButtonIndex] = useState(null);

  useEffect(() => {
    async function fetchDunes() {
      try {
        const response = await axios.get('https://wonky-ord-v2.dogeord.io/dunes');
        const parser = new DOMParser();
        const doc = parser.parseFromString(response.data, 'text/html');
        const duneList = Array.from(doc.querySelectorAll('ul li a')).map(a => a.textContent);
        setDunes(duneList.reverse()); // Start with Recent sort
      } catch (error) {
        console.error('Error fetching dunes:', error);
      }
    }

    fetchDunes();
  }, []);

  const fetchDuneDetails = async (duneName, index) => {
    if (selectedDuneIndex === index) {
      setSelectedDuneIndex(null);
      setDuneDetails(null);
      return;
    }

    try {
      const encodedName = duneName.split(' ').join('%E2%80%A2');
      const response = await axios.get(`https://wonky-ord-v2.dogeord.io/dune/${encodedName}`);
      const parser = new DOMParser();
      const doc = parser.parseFromString(response.data, 'text/html');

      const title = doc.querySelector('h1').textContent.trim();
      const details = {};
      doc.querySelectorAll('dl dt').forEach((dt) => {
        const key = dt.textContent.trim();
        const value = dt.nextElementSibling.textContent.trim();
        details[key] = value;
      });

      setSelectedDuneIndex(index);
      setDuneDetails(details);
    } catch (error) {
      console.error(`Error fetching dune details for ${duneName}:`, error);
    }
  };

  const handleSortChange = (option) => {
    setSortOption(option);
    if (option === 'Oldest') {
      setDunes(prevDunes => [...prevDunes].reverse());
    } else if (option === 'Recent') {
      setDunes(prevDunes => [...prevDunes].reverse());
    }
  };

  const handleSearchInputChange = (e) => {
    const value = e.target.value
      .toUpperCase()
      .replace(/ /g, '•');
    setSearchTerm(value);
  };

  const copyToClipboard = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedButtonIndex(index);
      setTimeout(() => setCopiedButtonIndex(null), 2000);
    }).catch((err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const filteredDunes = dunes.filter(dune =>
    dune.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="dunes-list-container">
      <div className="header-container">
        <h2>ETCHED DUNES</h2>
        <div className="search-and-sort">
          <input
            type="text"
            className="dune-search-bar"
            placeholder="Search for ticker"
            value={searchTerm}
            onChange={handleSearchInputChange}
          />
          <div className="sort-dropdown">
            <button className="sort-button">{sortOption} ▼</button>
            <div className="sort-options">
              <div onClick={() => handleSortChange('Oldest')}>Oldest</div>
              <div onClick={() => handleSortChange('Recent')}>Recent</div>
            </div>
          </div>
        </div>
      </div>
      <ul className="dunes-list">
        {filteredDunes.filter(dune => !dune.toUpperCase().includes('TEST')).map((dune, index) => (
          <li key={index} className="dune-item-container">
            <div
              className={`dune-item ${selectedDuneIndex === index ? 'active' : ''}`}
              onClick={() => fetchDuneDetails(dune, index)}
            >
              {dune}
            </div>
            {selectedDuneIndex === index && duneDetails && (
              <div className="dune-details">
                <h3 style={{ textAlign: 'center' }}>{dune}</h3>
                {Object.entries(duneDetails).map(([key, value], detailIndex) => (
                  <p key={detailIndex} style={{ textAlign: 'center' }}>
                    <strong>{key}:</strong> {value}
                    {(key.toLowerCase() === 'id' || key.toLowerCase() === 'amount') && (
                      <>
                        <br />
                        <button
                          onClick={() => copyToClipboard(value, detailIndex)}
                          className={`copy-button ${copiedButtonIndex === detailIndex ? 'copied' : ''}`}
                        >
                          Copy
                        </button>
                      </>
                    )}
                  </p>
                ))}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DunesList;
