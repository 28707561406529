import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DeployDune.css';

// Emoji data grouped by categories
const emojiCategories = {
  Faces: [
    '😀', '😁', '😂', '🤣', '😃', '😄', '😅', '😆', '😉', '😊', '😋', '😎', '😍', '😘',
    '😗', '😙', '😚', '🙂', '🤗', '🤔', '🤩', '🤨', '😐', '😑', '😶', '🙄', '😏', '😣',
    '😥', '😮', '🤐', '😯', '😪', '😫', '😴', '😌', '😛', '😜', '😝', '🤤', '😒', '😓',
    '😔', '😕', '🙃', '🤑', '😲', '☹️', '🙁', '😖', '😞', '😟', '😤', '😢', '😭', '😦',
    '😧', '😨', '😩', '🤯', '😬', '😰', '😱', '😳', '🤪', '😵', '😡', '😠', '🤬', '😷',
    '🤒', '🤕', '🤢', '🤮', '🤧', '😇', '🤠', '🥳', '🥺', '🤫', '🤭', '🧐', '🤓', '😈',
    '👿', '👹', '👺', '💀', '👻', '👽', '👾', '🤖', '🫡', '🫠'
  ],
  Animals: [
    '🐶', '🐱', '🐭', '🐹', '🐰', '🦊', '🐻', '🐼', '🐨', '🐯', '🦁', '🐮', '🐷', '🐽',
    '🐸', '🐵', '🙈', '🙉', '🙊', '🐒', '🐔', '🐧', '🐦', '🐤', '🐣', '🐥', '🦆', '🦢',
    '🦅', '🦉', '🦇', '🐺', '🐗', '🐴', '🦄', '🐝', '🐛', '🦋', '🐌', '🐞', '🐜', '🦗',
    '🕷', '🕸', '🐢', '🐍', '🦎', '🦂', '🦞', '🦀', '🦑', '🐙', '🦐', '🐠', '🐟', '🐡',
    '🐬', '🐳', '🐋', '🦈', '🐊', '🐅', '🐆', '🦓', '🦍', '🐘', '🦏', '🦛', '🐪', '🐫',
    '🦙', '🦒', '🐃', '🐂', '🐄', '🐎', '🐖', '🐏', '🐑', '🦌', '🐐', '🐓', '🦃', '🕊',
    '🐇', '🐁', '🐀', '🐿', '🦔', '🐾'
  ],
  Nature: [
    '🌵', '🎄', '🌲', '🌳', '🌴', '🌱', '🌿', '☘️', '🍀', '🎍', '🎋', '🍃', '🍂', '🍁',
    '🍄', '🌾', '💐', '🌷', '🌹', '🥀', '🌺', '🌸', '🌼', '🌻', '🌞', '🌝', '🌛', '🌜',
    '🌚', '🌕', '🌖', '🌗', '🌘', '🌑', '🌒', '🌓', '🌔', '🌙', '🌎', '🌍', '🌏', '💫',
    '⭐', '🌟', '✨', '⚡', '🔥', '💥', '☄️', '☀️', '🌤', '⛅', '🌥', '🌦', '🌧', '⛈',
    '🌩', '🌨', '❄️', '☃️', '⛄', '🌬', '💨', '💧', '💦', '☔', '☂️', '🌊', '🌫'
  ],
  Food: [
    '🍏', '🍎', '🍐', '🍊', '🍋', '🍌', '🍉', '🍇', '🍓', '🍈', '🍒', '🍑', '🥭', '🍍',
    '🥥', '🥝', '🍅', '🍆', '🥑', '🥦', '🥬', '🥒', '🌶', '🌽', '🥕', '🧄', '🧅', '🥔',
    '🍠', '🥐', '🍞', '🥖', '🥨', '🥯', '🥞', '🧇', '🧀', '🍖', '🍗', '🥩', '🥓', '🍔',
    '🍟', '🍕', '🌭', '🥪', '🌮', '🌯', '🥙', '🧆', '🥚', '🍳', '🥘', '🍲', '🥣', '🥗',
    '🍿', '🧈', '🧂', '🥫', '🍱', '🍘', '🍙', '🍚', '🍛', '🍜', '🍝', '🍢', '🍣', '🍤',
    '🍥', '🥮', '🍡', '🥟', '🥠', '🥡', '🦪'
  ],
  Objects: [
    '⚽', '🏀', '🏈', '⚾', '🎾', '🏐', '🏉', '🎱', '🏓', '🎯', '🎮', '🎲', '🎷', '🎺',
    '🎸', '🎻', '🎬', '🏹', '💎', '🔔', '🔒', '🔑', '📱', '💻', '📷', '📚', '🔦', '🕰️'
  ],
  Gestures: [
    '👍', '👎', '👊', '✊', '🤛', '🤜', '👏', '🙌', '👐', '🤲', '🤝', '🙏', '💪', '🖖',
    '✌️', '🤘', '🤟', '🤙', '👋', '🤚', '🖐', '✋', '🖕'
  ],
  Symbols: [
    '❤️', '💛', '💚', '💙', '💜', '🖤', '🤍', '🤎', '💔', '❣️', '💕', '💞', '💓', '💗',
    '💖', '💘', '💝', '💟', '☮️', '✝️', '☪️', '🕉️', '☸️', '✡️', '🔯', '☯️', '☦️', '🛐',
    '⛎', '♈', '♉', '♊', '♋', '♌', '♍', '♎', '♏', '♐', '♑', '♒', '♓', '🆔', '⚛️',
    '🉑', '💲', '💱', '💵', '💰'
  ]
};




function DeployDune({ isWalletConnected, walletAddress, handlePayment }) {
  const [name, setName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [limitPerMint, setLimitPerMint] = useState('');
  const [maxNrOfMints, setMaxNrOfMints] = useState('');
  const [status, setStatus] = useState('');
  const [orderId, setOrderId] = useState(null);
  const [fee, setFee] = useState(null);
  const [paymentAddress, setPaymentAddress] = useState('');
  const [deployTxId, setDeployTxId] = useState('');
  const [advanced, setAdvanced] = useState(false);
  const [decimals, setDecimals] = useState(0);
  const [mintAbsoluteStartBlockHeight, setMintAbsoluteStartBlockHeight] = useState('');
  const [mintAbsoluteStopBlockHeight, setMintAbsoluteStopBlockHeight] = useState('');
  const [mintRelativeStartBlockHeight, setMintRelativeStartBlockHeight] = useState('');
  const [mintRelativeEndBlockHeight, setMintRelativeEndBlockHeight] = useState('');
  const [optInForFutureProtocolChanges, setOptInForFutureProtocolChanges] = useState(false);
  const [mintingAllowed, setMintingAllowed] = useState(true);
  const [tooltip, setTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [activeCategory, setActiveCategory] = useState('Faces');

  const API_URL = 'https://spooksociety.info';

  const handleDeploy = async () => {
    try {
      console.log("Initiating deploy...");
      setStatus('Creating order...');
      const deployDetails = {
        name,
        symbol,
        limitPerMint: limitPerMint || 1,
        maxNrOfMints: maxNrOfMints || 1,
        decimals: decimals || 0,
        mintAbsoluteStartBlockHeight: advanced ? mintAbsoluteStartBlockHeight || null : null,
        mintAbsoluteStopBlockHeight: advanced ? mintAbsoluteStopBlockHeight || null : null,
        mintRelativeStartBlockHeight: advanced ? mintRelativeStartBlockHeight || null : null,
        mintRelativeEndBlockHeight: advanced ? mintRelativeEndBlockHeight || null : null,
        optInForFutureProtocolChanges: optInForFutureProtocolChanges || false,
        mintingAllowed: mintingAllowed || true,
        turbo: advanced ? 'false' : 'true',
      };

      const response = await axios.post(`${API_URL}/api/deploy`, deployDetails);
      console.log('Deploy order created, response:', response.data);
      setOrderId(response.data.orderId);
      setFee(response.data.fee);
      setPaymentAddress(response.data.paymentAddress);
      setStatus('Pending payment...');

      if (isWalletConnected) {
        try {
          const txRes = await window.doge.requestTransaction({
            recipientAddress: response.data.paymentAddress,
            dogeAmount: response.data.fee,
          });
          console.log('Transaction response:', txRes);
          handlePayment(txRes);
          setStatus('Payment complete. Waiting for etching...');
        } catch (error) {
          console.error('Error completing payment:', error);
          setStatus('Error completing payment: ' + error.message);
        }
      } else {
        pollPaymentStatus(response.data.orderId); // Start polling for payment
      }
    } catch (error) {
      console.error('Error initiating deploy:', error);

      if (error.message.includes('symbol should have exactly 1 character')) {
        setStatus('Deploy failed: Dune symbol must be a single character.');
      } else {
        setStatus('Error initiating etch: ' + (error.response ? error.response.data.message : error.message));
      }
    }
  };

  const pollPaymentStatus = async (orderId) => {
    const interval = setInterval(async () => {
      try {
        console.log("Checking etching status for order:", orderId);
        const response = await axios.get(`${API_URL}/api/status/${orderId}`);

        if (response.data.status === 'completed' && response.data.deployTxId) {
          setDeployTxId(response.data.deployTxId);
          setStatus(`${name} ${symbol} has been deployed successfully.`);
          clearInterval(interval);
        } else if (response.data.status === 'error') {
          setStatus('Deployment failed. Please try again.');
          clearInterval(interval);
        } else if (response.data.status === 'cancelled') {
          setStatus('Deployment has been cancelled due to payment timeout.');
          clearInterval(interval);
        }
      } catch (error) {
        console.error('Error checking deployment status:', error);
        setStatus('Error checking deployment status: ' + (error.response ? error.response.data.message : error.message));
      }
    }, 10000);
    return () => clearInterval(interval);
  };

  const toggleTooltip = (e) => {
    if (!tooltip) {
      const container = e.currentTarget.closest('.deploy-container');
      const containerRect = container.getBoundingClientRect();
      const xOffset = 15;
      const yOffset = 15;

      let x = e.clientX - containerRect.left + xOffset;
      let y = e.clientY - containerRect.top + yOffset;

      const tooltipHeight = 100;
      const tooltipWidth = 200;
      if (x + tooltipWidth > window.innerWidth) {
        x = window.innerWidth - tooltipWidth - xOffset;
      }
      if (y + tooltipHeight > window.innerHeight) {
        y = window.innerHeight - tooltipHeight - yOffset;
      }

      setTooltipPosition({ x, y });
    }
    setTooltip(!tooltip);
  };

  const resetForm = () => {
    setName('');
    setSymbol('');
    setLimitPerMint('');
    setMaxNrOfMints('');
    setDecimals(0);
    setMintAbsoluteStartBlockHeight('');
    setMintAbsoluteStopBlockHeight('');
    setMintRelativeStartBlockHeight('');
    setMintRelativeEndBlockHeight('');
    setOptInForFutureProtocolChanges(false);
    setMintingAllowed(true);
    setStatus('');
    setOrderId(null);
    setFee(null);
    setPaymentAddress('');
    setDeployTxId('');
  };

  const filteredEmojis = emojiCategories[activeCategory];

  return (
    <div className="deploy-container">
      <div className="deploy-header">
        <h2 className="deploy-title">Etch Dune</h2>
        <button
          className="advanced-button"
          onClick={() => setAdvanced(!advanced)}
        >
          {advanced ? 'Simple' : 'Advanced'}
        </button>
      </div>
      {!deployTxId ? (
        <>
          <div className="input-group">
            <input
              type="text"
              placeholder="Dune Name"
              value={name}
              onChange={(e) => setName(e.target.value.toUpperCase().replace(/\s/g, '•'))}
            />
          </div>
          <div className="input-group emoji-picker-wrapper">
            <input
              type="text"
              placeholder="Symbol (click the 🐾 to select an emoji)"
              value={symbol}
              readOnly
              className="emoji-input"
            />
            <button
              className="emoji-picker-toggle"
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            >
              {symbol || '🐾'}
            </button>
            {showEmojiPicker && (
              <div className="emoji-picker">
                <div className="emoji-categories">
                  {Object.keys(emojiCategories).map((category) => (
                    <button
                      key={category}
                      className={`emoji-category ${
                        activeCategory === category ? 'active' : ''
                      }`}
                      onClick={() => setActiveCategory(category)}
                    >
                      {category}
                    </button>
                  ))}
                </div>
                <div className="emoji-grid">
                  {filteredEmojis.map((emoji) => (
                    <button
                      key={emoji}
                      className="emoji"
                      onClick={() => {
                        setSymbol(emoji);
                        setShowEmojiPicker(false);
                      }}
                    >
                      {emoji}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="input-group">
            <input
              type="number"
              placeholder="Limit Per Mint"
              value={limitPerMint}
              onChange={(e) => setLimitPerMint(e.target.value)}
            />
          </div>
          <div className="input-group">
            <input
              type="number"
              placeholder="Max Number of Mints"
              value={maxNrOfMints}
              onChange={(e) => setMaxNrOfMints(e.target.value)}
            />
          </div>
          {advanced && (
            <div className="advanced-settings">
              <div className="input-group">
                <input
                  type="number"
                  placeholder="Decimals (Default: 0)"
                  value={decimals}
                  onChange={(e) => setDecimals(e.target.value)}
                />
              </div>
              <div className="input-group">
                <input
                  type="number"
                  placeholder="Absolute Start Block Height"
                  value={mintAbsoluteStartBlockHeight}
                  onChange={(e) => setMintAbsoluteStartBlockHeight(e.target.value)}
                />
              </div>
              <div className="input-group">
                <input
                  type="number"
                  placeholder="Absolute Stop Block Height"
                  value={mintAbsoluteStopBlockHeight}
                  onChange={(e) => setMintAbsoluteStopBlockHeight(e.target.value)}
                />
              </div>
              <div className="input-group">
                <input
                  type="number"
                  placeholder="Relative Start Block Height"
                  value={mintRelativeStartBlockHeight}
                  onChange={(e) => setMintRelativeStartBlockHeight(e.target.value)}
                />
              </div>
              <div className="input-group">
                <input
                  type="number"
                  placeholder="Relative End Block Height"
                  value={mintRelativeEndBlockHeight}
                  onChange={(e) => setMintRelativeEndBlockHeight(e.target.value)}
                />
              </div>
              <div className="checkbox-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={optInForFutureProtocolChanges}
                    onChange={(e) => setOptInForFutureProtocolChanges(e.target.checked)}
                  />
                  Opt-in for Future Protocol Changes
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={mintingAllowed}
                    onChange={(e) => setMintingAllowed(e.target.checked)}
                  />
                  Minting Allowed
                </label>
              </div>
            </div>
          )}

          <button onClick={handleDeploy}>Etch</button>
          {status && <p>{status}</p>}
          {orderId && !isWalletConnected && (
            <div>
              <p>Payment Address: {paymentAddress}</p>
              <p>Fee: {fee} DOGE</p>
              <p>Please send the fee to the payment address above.</p>
            </div>
          )}
        </>
      ) : (
        <div className="status-container">
          <p>{status}</p>
          <p>Order ID: {orderId}</p>
          <p>TX ID: {deployTxId}</p>
          <button onClick={resetForm}>Etch New Dune</button>
        </div>
      )}
    </div>
  );
}

export default DeployDune;
