// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.check-dunes-container {
  border: 2px solid rgb(80, 250, 168); /* Border color */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px; /* Adjust based on your needs */
  margin: 20px auto;
  text-align: center; /* Center align text and button */
}

.input-group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.input-group input {
  width: 300px;
  padding: 10px;
  margin-right: 10px;
}

.input-group button {
  padding: 10px 20px;
}

.dunes-list {
  margin-top: 20px;
}

.dune-item {
  padding: 10px 0;
}

.error {
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/components/CheckDunes.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC,EAAE,iBAAiB;EACtD,aAAa;EACb,kBAAkB;EAClB,uCAAuC;EACvC,gBAAgB,EAAE,+BAA+B;EACjD,iBAAiB;EACjB,kBAAkB,EAAE,iCAAiC;AACvD;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".check-dunes-container {\r\n  border: 2px solid rgb(80, 250, 168); /* Border color */\r\n  padding: 20px;\r\n  border-radius: 8px;\r\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n  max-width: 800px; /* Adjust based on your needs */\r\n  margin: 20px auto;\r\n  text-align: center; /* Center align text and button */\r\n}\r\n\r\n.input-group {\r\n  display: flex;\r\n  justify-content: center;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.input-group input {\r\n  width: 300px;\r\n  padding: 10px;\r\n  margin-right: 10px;\r\n}\r\n\r\n.input-group button {\r\n  padding: 10px 20px;\r\n}\r\n\r\n.dunes-list {\r\n  margin-top: 20px;\r\n}\r\n\r\n.dune-item {\r\n  padding: 10px 0;\r\n}\r\n\r\n.error {\r\n  color: red;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
